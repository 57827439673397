<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'

export default {
  name: 'indexCard',
  data() {
    return {
      ...api.command.getState(),
      batchNoArray: []
    }
  },
  mounted() {
    if (this.$route.query.id) {
      getAction('/api/farmBalanceCard/queryBatchNoList?balanceId=' + this.$route.query.id).then(e => {
        this.batchNoArray = e.data
          ? e.data.map(e => {
              return {
                text: e,
                value: e
              }
            })
          : []
      })
    }

    api.command.getList.call(this, {
      url: '/farmBalanceCard/list',
      current: 1,
      paramsValue: {
        balanceId: this.$route.query.id
      }
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '手机号',
          key: 'telephone',
          type: 'input'
        },
        {
          name: '是否绑定',
          type: 'select',
          key: 'bindFlag',
          typeData: [
            {
              name: '未绑定',
              value: '0'
            },
            {
              name: '绑定',
              value: '1'
            }
          ]
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'cardCode',
          title: '卡号',
          align: 'left'
        },
        {
          dataIndex: 'telephone',
          title: '绑定手机',
          align: 'left'
        },
        {
          dataIndex: 'usedAmount',
          title: '消费金额',
          align: 'left',
          sorter: (a, b) => a.usedAmount - b.usedAmount
        },
        {
          dataIndex: 'unUsedAmount',
          title: '剩余金额',
          align: 'left',
          sorter: (a, b) => a.unUsedAmount - b.unUsedAmount
        },
        {
          dataIndex: 'allAmount',
          title: '发放金额',
          align: 'left',
          sorter: (a, b) => a.allAmount - b.allAmount
        },
        {
          dataIndex: 'batchNo',
          title: '批次',
          align: 'left',
          filters: this.batchNoArray,
          filterMultiple: false
        },
        {
          dataIndex: 'lastUserTime',
          title: '最后消费时间',
          align: 'left'
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: '/farmBalanceCard/del',
                    params: {
                      id: records.id
                    }
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getDrawerForm(source, formData) {
      const form = [
        {
          form: [
            {
              name: '新增卡数',
              key: 'num',
              labelAlign: 'right',
              type: 'inputNumber',
              rules: [
                {
                  required: true,
                  type: 'number'
                }
              ]
            }
          ]
        }
      ]
      return form
    },
    handlePopup(dataSource = {}) {
      let that = this
      apiTool.showDrawer({
        title: '设置',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: formData => that.getDrawerForm(dataSource, formData),
          form: dataSource,
          formProps: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          }
        },
        success: ({ data, setHidden }) => {
          if (!data.num) {
            this.$message.error('请填写新增卡数')
            return
          } else {
            api.command.createPut
              .call(that, {
                url: '/farmBalanceCard/addBalanceNum?balanceId=' + this.$route.query.id + '&num=' + data.num
              })
              .then(setHidden)
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.handlePopup()
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
